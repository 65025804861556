import * as React from "react";
import type { HeadFC } from "gatsby";
import { useEffect } from "react";
import Header_Layout from "../../components/layouts/Header_Layout";

const ConditionsPage = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://app.termly.io/embed-policy.min.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);
  return (
    <Header_Layout>
      <div
        name="termly-embed"
        data-id="54d0d6f1-5e69-4f68-9e69-b2ad2eeeb809"
        data-type="iframe"
      ></div>
    </Header_Layout>
  );
};

export default ConditionsPage;

export const Head: HeadFC = () => <title>Terms and Conditions</title>;
